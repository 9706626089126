import { IntlConfigWithMessagesForLocale } from './types';
import { useIntl } from 'react-intl';

/**
 * Returns internationalization props from a parent react-intl provider, if one exists.
 *
 * If none exists, returns an {@link IntlConfigWithMessagesForLocale|IntlConfigWithMessagesForLocale} object with empty fields.
 *
 * @private
 */
export function useReactIntl(): IntlConfigWithMessagesForLocale {
  try {
    const { defaultLocale, locale, messages } = useIntl();

    return {
      defaultLocale,
      locale,
      messages,
    };
  } catch (e: unknown) {
    /**
     * When react-intl's useIntl() is called and no parent react-intl provider exists,
     * react-intl throws an error prepended with the string "[React Intl]".
     */
    const hasNoReactIntlParent = ((e as Error).message).includes('React Intl');

    if (hasNoReactIntlParent) {
      return {
        defaultLocale: undefined,
        locale: undefined,
        messages: undefined,
      };
    }

    // Throw an unrelated error that occured.
    throw e;
  }
}

export function useParentIntlProvider(): IntlConfigWithMessagesForLocale {
  // TODO: handle other libs like Adobe-React-Intl, react-aria
  return useReactIntl();
}
