/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import 'react-intl';
import '@quarry/intl';
import 'react';
import 'react-dom';
// Components needed by Shell ASAP and rarely change - Import them here to embed in react.js
import {defaultTheme, ProgressCircle, Provider} from '@adobe/react-spectrum';

export {defaultTheme};
export const provider = Provider;
export const progress = ProgressCircle;
