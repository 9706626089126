/**
 * Error thrown when no locale is provided to IntlProvider or any parent internationalization providers.
 */
export class MissingLocaleError extends Error {
  constructor() {
    super(
      `[@quarry/intl Error MISSING_LOCALE] Missing locale for IntlProvider. Please provide a \`locale\` prop. If your IntlProvider is the child of another internationalization provider, you may provide a \`locale\` prop to only the parent provider and it will be used automatically.`,
    );
  }
}
