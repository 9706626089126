import {
  AllMessages,
  IntlConfigWithAllMessages,
  IntlConfigWithMessagesForLocale,
  Messages,
  ReactIntlProviderProps,
} from './types';
import { MissingLocaleError } from './errors';

/**
 * Provides props and fallback behavior for a react-intl IntlProvider that's optionally nested inside another IntlProvider.
 * - Allows shareable components to internationalize themselves while applying settings like locale and messages from the parent context.
 * - Sets a default `defaultLocale` of 'en-US'.
 * - Lets locales with no country code fall back to BCP 47 language tags (ex: "xx-XX").
 *
 * The ideal use case for this hook is to provide props for an IntlProvider that wraps a shareable component meant for consumption in internationalized parent apps. It can also be used with an app's topmost IntlProvider for its defaultLocale and fallback features.
 *
 * In the future, this will also support communication with Adobe-Intl-React's I18nProvider and react-aria's I18nProvider as parent contexts.
 *
 * @param {IntlConfigWithAllMessages} intlConfig Props for the current internationalization context
 * @param {IntlConfigWithMessagesForLocale | IntlConfigWithAllMessages} parentIntlConfig Props for a parent internationalization context
 * @returns {ReactIntlProviderProps} Props for a react-intl `IntlProvider` component
 */
export function useIntlProvider(
  intlConfig: IntlConfigWithAllMessages,
  parentIntlConfig: IntlConfigWithMessagesForLocale | IntlConfigWithAllMessages = {},
): ReactIntlProviderProps {
  let { defaultLocale, locale } = intlConfig;
  const { defaultLocale: parentDefaultLocale, locale: parentLocale } = parentIntlConfig;

  defaultLocale = defaultLocale || parentDefaultLocale || 'en-US';
  locale = locale || parentLocale;

  if (!locale) {
    throw new MissingLocaleError();
  }

  const messages = useMessages(intlConfig, parentIntlConfig);

  return {
    defaultLocale,
    locale,
    messages,
  };
}

/**
 * Return the messages to provide to a react-intl `IntlProvider`.
 *
 * 1. In the messages dictionary, convert "xx_XX" locale keys to "xx-XX"
 * 2. Choose the best available locale to return messages for
 * 3. Merge messages with messages from a parent internationalization context, if one exists
 *
 * @private
 * @param {IntlConfigWithAllMessages} intlConfig Props for the current internationalization context
 * @param {IntlConfigWithMessagesForLocale | IntlConfigWithAllMessages} parentIntlConfig Props for a parent internationalization context
 * @returns {Messages} Processed and merged messages for a react-intl `IntlProvider` component
 */
function useMessages(
  intlConfig: IntlConfigWithAllMessages,
  parentIntlConfig: IntlConfigWithMessagesForLocale | IntlConfigWithAllMessages = {},
): Messages {
  const { defaultLocale, locale, messages } = intlConfig;
  const {
    defaultLocale: parentDefaultLocale,
    locale: parentLocale,
    messages: parentMessages,
  } = parentIntlConfig;

  const normalizedMessages = normalizeMessages(messages);
  const messagesOfLocale = getMessagesOfLocale(
    normalizedMessages,
    defaultLocale || '',
    locale || '',
    parentDefaultLocale || '',
    parentLocale || '',
  );
  const mergedMessages = mergeMessagesOfLocaleWithParentMessages(
    parentLocale || '',
    messagesOfLocale,
    parentMessages,
  );

  return mergedMessages;
}

/**
 * Converts hyphens to underscores and returns locale.
 *
 * @private
 * @param {string} locale Locale formatted as BCP 47 language tag, delimited by hyphen or underscore (ex: "xx-XX" or "xx_XX")
 * @returns {string} Locale with underscores replaced by hyphens (ex: "xx-XX")
 */
function normalizeLocale(locale: string): string {
  return locale.replace(/_/g, '-');
}

/**
 * Normalize each locale key in a messages object.
 *
 * @private
 * @param {AllMessages} messages All messages
 * @returns {AllMessages} All messages with normalized locale keys
 */
function normalizeMessages(messages?: AllMessages): AllMessages {
  const normalizedMessages: AllMessages = {};

  for (const localeKey in messages) {
    normalizedMessages[normalizeLocale(localeKey)] = messages[localeKey];
  }

  return normalizedMessages;
}

/**
 * Type guard for the AllMessages type, to distinguish it from Messages.
 *
 * This crude implementation takes a messages object and a locale key, then
 * checks if the locale exists on the messages object. If it does, then we
 * assume that `messages` has locale string keys, so its type is AllMessages.
 *
 * @private
 * @param {Messages | AllMessages | undefined} messages Object of messages for an internationalization provider
 * @param {string} locale A locale key to search for in the messages object
 * @returns {messages is AllMessages}
 */
function isAllMessages(
  messages: Messages | AllMessages | undefined,
  locale?: string,
): messages is AllMessages {
  return Boolean(messages) && (messages as AllMessages)[locale as string] !== undefined;
}

/**
 * Merge messages from `messages` prop and any parent messages, if they exist.
 *
 * @private
 * @param {string} parentLocale Parent provider's `locale` prop
 * @param {Messages} messagesOfLocale IntlProvider's messages for the best available locale
 * @param {Messages | AllMessages} parentMessages Parent provider's `messages` prop
 * @returns {Messages} Messages merged with parent messages
 */
function mergeMessagesOfLocaleWithParentMessages(
  parentLocale = '',
  messagesOfLocale: Messages = {},
  parentMessages: Messages | AllMessages = {},
): Messages {
  const parentMessagesOfParentLocale = isAllMessages(parentMessages, parentLocale)
    ? parentMessages[parentLocale] || {}
    : parentMessages;

  return {
    ...parentMessagesOfParentLocale,
    ...messagesOfLocale,
  } as Messages;
}

/**
 * Return messages for the given locale.
 *
 * If any locale argument is missing a country code, it will be matched to the closest locale supported in `messages` that does include a country code.
 *
 * To determine the locale, the order of precedence is:
 * 1. locale
 * 2. parent locale
 * 3. default locale
 * 4. parent default locale
 * 5. "en-US"
 *
 * @private
 * @param {AllMessages} messages Messages with locale keys formatted as BCP 47 language tags (ex: "xx-XX")
 * @param {string} locale IntlProvider's `locale` prop
 * @param {string} defaultLocale IntlProvider's `defaultLocale` prop
 * @param {string} parentLocale Parent provider's `locale` prop
 * @param {string} parentDefaultLocale Parent provider's `defaultLocale` prop
 * @returns {Messages} Messages for the given locale
 */
function getMessagesOfLocale(
  messages: AllMessages,
  defaultLocale: string,
  locale: string,
  parentDefaultLocale: string,
  parentLocale: string,
): Messages {
  const [closestLocale, closestParentLocale, closestDefaultLocale, closestParentDefaultLocale] = [
    locale,
    parentLocale,
    defaultLocale,
    parentDefaultLocale,
  ].map((loc) => getClosestLocale(loc));

  return (
    messages[closestLocale] ||
    messages[closestParentLocale] ||
    messages[closestDefaultLocale] ||
    messages[closestParentDefaultLocale] ||
    messages['en-US']
  );
}

export const LOCALE_MAPPING: Record<string, string[]> = {
  'en-US': [
    'en-AE',
    'en-GB',
    'en-HR',
    'en-KE',
    'en-IL',
    'en-MU',
    'en-NG',
    'en-TT',
    'en-US',
    'en-XM',
    'en-MO',
    'en-LK',
    'en-VN',
  ],
  'de-DE': ['de-DE'],
  'es-ES': [
    'es-BO',
    'es-DO',
    'es-PA',
    'es-ES',
    'es-LA',
    'es-MX',
    'es-NA',
    'es-PY',
    'es-SV',
    'es-UY',
  ],
  'fr-FR': ['fr-CA', 'fr-FR', 'fr-MA', 'fr-XM'],
  'it-IT': ['it-IT'],
  'ja-JP': ['ja-JP'],
  'ko-KR': ['ko-KR'],
  'pt-BR': ['pt-BR'],
  'zh-CN': ['zh-CN', 'zh-HANS', 'zh-HANS-CN'],
  'zh-TW': ['zh-TW', 'zh-HANT', 'zh-HANT-TW'],
};

/**
 * Return the closest locale from the nine that DXUE supports for localizing messages
 * If DXUE supports the language code but the country code is one of a few other specific values supported by Unified Shell, return the DXUE-supported locale as a fallback.
 * @see https://git.corp.adobe.com/exc/unified-shell/blob/master/packages/core/src/utils/locale.ts#L21
 *
 * If the locale is only a language code without a country code (ex: "ja"), search the DXUE-supported locales and return the BCP 47 locale with that language code (ex: "ja-JP").
 *
 * @private
 * @param {string} locale Locale with language code and optional country code (ex: "xx" or "xx-XX")
 * @returns {string} The closest supported locale
 */

function getClosestLocale(locale: string): string {
  if (!locale) {
    return ''; // Return empty string if locale is empty
  }

  let matchingKey = null;
  const languageCode = locale.split('-')[0];

  for (const key in LOCALE_MAPPING) {
    if (LOCALE_MAPPING[key].includes(locale)) {
      return key; // Exact match found, return immediately
    }

    if (!matchingKey && key.startsWith(languageCode)) {
      matchingKey = key; // Set matchingKey if it's the first match based on language code
    }
  }

  return matchingKey || locale;
}
