import React, {ComponentType, ReactElement} from 'react';
import {AllMessages} from './types';
import {IntlProvider} from './IntlProvider';

/**
 * Wraps a component with IntlProvider so that the wrapped component can utilize intl hooks
 * @param Component - React component to wrap
 * @param localizedMessages - dictionary of messages to pass to IntlProvider
 */
export function withIntlProvider<T>(Component: ComponentType<T>, localizedMessages: AllMessages) {
  return function Wrapped(props: T & {locale?: string}): ReactElement {
    return (
      <IntlProvider locale={props.locale} messages={localizedMessages}>
        <Component {...props as T} />
      </IntlProvider>
    );
  }
}
