export const mergeDictionaries = (dictionaries: {}[]): {} => {
  return dictionaries.reduce(
    (aggregator: { [key: string]: {} }, current: { [key: string]: {} }) => {
      for (const [key, value] of Object.entries(current)) {
        aggregator[key] = {
          ...aggregator[key],
          ...value,
        };
      }
      return aggregator;
    },
    {},
  );
};
