import React, { useMemo } from 'react';
import { AllMessages } from './types';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useIntlProvider } from './useIntlProvider';
import { useParentIntlProvider } from './useParentIntlProvider';

interface IntlProviderProps {
  /** Default locale for the IntlProvider. `useIntlProvider` defaults it to "en-US". */
  defaultLocale?: string;
  /**
   * Locale for the IntlProvider. When omitted, will automatically use the locale of a parent react-intl IntlProvider, if it exists. When passed in, overrides the parent provider's locale.
   *
   * Must be passed in if the the parent context uses a library other than react-intl for its internationalization provider or if no parent internationalization context exists. */
  locale?: string;
  /** Messages for all supported locales */
  messages?: AllMessages;
  /** Children to internationalize */
  children: React.ReactNode;
}

/**
 * Wraps a react-intl IntlProvider and augments it with extra features and fallback behavior.
 *
 * Gets info from a parent internationalization context if it exists, then delegates all logic to `useIntlProvider`.
 */
export const IntlProvider = ({
  children,
  defaultLocale,
  locale,
  messages,
  ...props
}: IntlProviderProps): JSX.Element => {
  const intlConfig = { defaultLocale, locale, messages };
  const parentIntlConfig = useParentIntlProvider();
  const intlProviderProps = useMemo(() => useIntlProvider(intlConfig, parentIntlConfig), [
    ...Object.values(intlConfig),
    ...Object.values(parentIntlConfig),
  ]);

  return (
    <ReactIntlProvider {...intlProviderProps} {...props}>
      {children}
    </ReactIntlProvider>
  );
};
